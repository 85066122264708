import React from 'react';
import PropTypes from 'prop-types';
import './layout.css';
import { NoSsr } from '@material-ui/core';

function Layout({ maxWidth, children }) {
  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth,
        fontFamily: 'sans-serif',
        fontWeight: 'normal',
        fontSize: '12px',
        letterSpacing: 'normal',
        color: '#4a4a4a',
      }}
    >
      <NoSsr>
        <main>{children}</main>
      </NoSsr>
    </div>
  );
}

Layout.defaultProps = {
  maxWidth: '65%',
};

Layout.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
