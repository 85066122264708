export const RoleId = {
  FINANCE: 4,
  LEGAL: 3,
  MARKETING: 2,
  ACCOUNT_MANAGER: 1,
  ADMIN: 0,
};

export const OperationStatus = {
  Approved: 'approved',
  Merged: 'merged',
  Inactive: 'inactive',
  PendingOnVasp: 'pendingOnVasp',
  PendingOnAcc: 'pendingOnAccountManager',
  PendingOnApprover: 'pendingOnApprover',
  Expired: 'expired',
};

export const OperationRegistrationType = {
  SygnaBridge: 0,
  SygnaGate: 1,
  SygnaHub: 2,
  SygnaEmailProtocol: 3,
};

export const OperationStatusContents = {
  approved: 'approved',
  merged: 'merged',
  inactive: 'inactive',
  pendingOnVasp: 'pending On\nVasp',
  pendingOnAccountManager: 'pending On\nAccount Manager',
  pendingOnApprover: 'pending On\nApprover',
  expired: 'expired',
};

export const Operations = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  RETURN: 'RETURN',
  RESEND: 'RESEND',
  APPROVE: 'APPROVE',
};

export const MIN_FROM_DATE = '2000-01-01';
export const MAX_TO_DATE = '2100-01-01';

export const TransferStatus = [
  'Pending on Beneficiary',
  'Pending on Originator',
  'Accepted',
  'Confirmed',
  'Rejected',
  'Timeout',
  'Validate Address Failed',
  'Unknown',
];

export const INVALID_ENDING_TIME_ERROR = {
  TOO_SHORT: 0,
  LESS_THAN_STARTING_TIME: 1,
};

export const ErrorMessages = {
  Required: 'Required',
  InvalidUpperCaseLettersLength: (validLength) =>
    `This field should contain ${validLength} upper-case letters.`,
  InvalidURLFormat: `Invalid url format.`,
  InvalidIPAddressFormat: `Your IP address should be in IPv4 or IPv6 format.`,
  InvalidPemKey: 'Invalid format.',
  InvalidEmailFormat: `Invalid email format.`,
  InvalidTokenExpirationPeriod: `Token expiration period should be between 1 ~ 14 days.`,
  InvalidPureTextFormat: 'Invalid Format. It should be alphabet or number',
  InvalidUpperCaseLettersOrNumbersLength: (validLength) =>
    `This field should contain ${validLength} upper-case letters or numbers.`,
  InvalidDateFormat: `Invalid Date Format`,
  InvalidEndingTime: (error) => {
    if (error === INVALID_ENDING_TIME_ERROR.TOO_SHORT) {
      return 'Ending time should be at least 5 minutes away from the current time.';
    }
    return 'Ending time should be greater than the starting time.';
  },
  InvalidGoLiveDt: 'Go-live date should be one day away from the current time.',
  InvalidEstimateValue: 'Value should be gratter than 0.',
  InvalidAddressDuplicated: 'The address and extraInfo is duplicated',
  InvalidEmailAddressesEmpty: `Email addresses should not be empty, please press add button to add email address`,
  ExistedVaspCode:
    'The combination of VASP Code (VASP Code Name+Country Code+City Code) is already existed, please modify your VASP Code',
  ExistedEmail:
    "The email address is already existed, please modify it (eg. abc+test01@domain.com), and make sure it's able to receive mails.",
  TooManyCustomVasp: 'You can only add about 100 custom VASPs',
};

export const CELL_TYPE = {
  NORMAL: 0,
  LOADING: 1,
  END: 2,
};

export const BundleServiceStatus = {
  Activated: 'activated', // 該服務已啟用
  Deactivated: 'deactivated', // sygna不支援該服務了
  Disabled: 'disabled', // account manager手動停止該服務
  Upcoming: 'upcoming', // 該服務即將啟用
  Expired: 'expired', // 該服務結束時間到了
};

export const VaspRegulationStatus = {
  Regulated: 'regulated',
  PendingOnAcc: 'pendingOnAccountManager',
  PendingOnApprover: 'pendingOnApprover',
  Unknown: 'unknown',
  Delisted: 'delisted',
};

export const VaspRegisterType = {
  Bridge: 0,
  Gate: 1,
  Hub: 2,
  EmailProtocol: 3,
};

export const VaspServerStatus = {
  HEALTHY: 'healthy',
  UNHEALTHY: 'unhealthy',
  UNKNOWN: 'unknown',
};
