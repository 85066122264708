import { navigate as gatsbyNavigate } from 'gatsby';
import lookup from 'country-code-lookup';
import Ajv from 'ajv';
import moment from 'moment';
import { RoleId, OperationStatus, Operations } from '../constants';
import $ from '../config';

const ajv = new Ajv();

export const decodeJWTPayload = (token) => {
  if (typeof window === 'undefined' || !token) {
    return {};
  }
  return JSON.parse(window.atob(token.split('.')[1]));
};

export const checkJWTExpired = (token) => {
  // return true if expired
  const payload = decodeJWTPayload(token);
  return payload.exp * 1000 < Date.now();
};

// need to check if window is defined
export const getToken = () => typeof window !== 'undefined' && window.localStorage.getItem('token');
export const setToken = (token) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (!token) {
    window.localStorage.removeItem('token');
  } else {
    window.localStorage.setItem('token', token);
  }
};

export const getRoleId = (token) => {
  // parse token to show user data, is it better to use JWT library?
  const user = checkJWTExpired(token) || decodeJWTPayload(token);
  if (typeof user !== 'undefined' && typeof user.roleId !== 'undefined') {
    return user.roleId;
  }
  return 999;
};

export const genNavigatePath = (target) => {
  if (!$.GATSBY_ENV) {
    return target;
  }
  if (target === '/') {
    return '/';
  }
  return `${target}/index.html`;
};

export const navigate = (target, state) =>
  typeof window !== 'undefined' ? gatsbyNavigate(genNavigatePath(target), state) : () => {};

export const getVaspCode = (vasp) =>
  `${vasp.vasp_code_prefix || ''}${vasp.country_code || ''}${vasp.city_code || ''}${
    vasp.branch_code || ''
  }`;

export const isVaspEditableByRole = (vasp, roleId) => {
  const { operation, vasp_status: vaspStatus } = vasp;

  if (operation === Operations.DELETE) {
    return false;
  }

  if (vaspStatus === OperationStatus.Inactive) {
    return false;
  }

  if (roleId === RoleId.ACCOUNT_MANAGER && vaspStatus === OperationStatus.PendingOnVasp) {
    return true;
  }
  if (roleId === RoleId.ACCOUNT_MANAGER && vaspStatus === OperationStatus.PendingOnAcc) {
    return true;
  }
  if (roleId === RoleId.ADMIN && vaspStatus === OperationStatus.PendingOnVasp) {
    return true;
  }
  if (roleId === RoleId.ADMIN && vaspStatus === OperationStatus.PendingOnApprover) {
    return true;
  }
  if (roleId === RoleId.ADMIN && vaspStatus === OperationStatus.Approved) {
    return true;
  }
  if (roleId === RoleId.ADMIN && vaspStatus === OperationStatus.Merged) {
    return true;
  }

  if (
    (roleId === RoleId.ADMIN || roleId === RoleId.ACCOUNT_MANAGER) &&
    vaspStatus === OperationStatus.Expired
  ) {
    return true;
  }

  return false;
};

export const parseGraphQLErrorMessage = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  const { graphQLErrors, message } = error;
  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors[0].message;
  }
  return message;
};

export const transferDataToSubmitArg = (data) => {
  const notificationReceivers = (data.raw_receivers || []).map((receiver) => receiver.value);
  const assets = (data.raw_assets || []).map((asset) => {
    const { address, currency, raw_extra_info = [] } = asset;
    const { currency_id: currencyID } = currency;

    return {
      address,
      currency_id: currencyID,
      addr_extra_info: raw_extra_info.map((e) => ({
        [e.key]: e.value,
      })),
    };
  });

  return {
    go_live_dt: data.go_live_dt || null,
    vasp_code_prefix: data.vasp_code_prefix,
    country_code: data.country_code.country_code,
    city_code: data.city_code,
    company_id: data.company_id,
    vasp_category: data.vasp_category,
    vasp_receive_url: data.vasp_receive_url && `https://${data.vasp_receive_url}`,
    vasp_fixed_ip:
      data.vasp_fixed_ip &&
      data.vasp_fixed_ip
        .split(',')
        .filter((e) => !!e)
        .map((e) => e.trim())
        .filter((e) => !!e),
    pem_pubkey: `-----BEGIN PUBLIC KEY-----\n${data.pem_pubkey}\n-----END PUBLIC KEY-----`,
    // if a is undefined a && `a${a}` will be undefined
    // if a is a then a && `a${a}` will be `aa`
    callback_txid_url: data.callback_txid_url && `https://${data.callback_txid_url}`,
    callback_validate_addr_url:
      data.callback_validate_addr_url && `https://${data.callback_validate_addr_url}`,
    callback_host_url: data.callback_host_url && `https://${data.callback_host_url}`,
    notification_receivers: notificationReceivers,
    callback_vasp_server_health_check_url:
      data.callback_vasp_server_health_check_url &&
      `https://${data.callback_vasp_server_health_check_url}`,
    assets,
    estimated_customer_number: Number(data.estimated_customer_number),
    estimated_annual_transaction_volume: Number(data.estimated_annual_transaction_volume),
    estimated_annual_travel_rule_data_transfer_number: Number(
      data.estimated_annual_travel_rule_data_transfer_number,
    ),
    paid_gtr: Boolean(data.paid_gtr),
    is_gtr_active: Boolean(data.is_gtr_active) || false,
    gtr_expired_date: data.gtr_expired_date || null,
    // It's the checkbox indicating if originating VASP check CODE in the registration form.
    paid_code: Boolean(data.paid_code),
    // It's the checkbox indicating account manager activate/deactivate CODE on back office.
    is_code_active: Boolean(data.is_code_active) || false,
    // It's the expired date which is set by account manager.
    code_expired_date: data.code_expired_date || null,
  };
};

export const getCountryCodes = () =>
  lookup.countries.map((country) => ({
    country_code: country.iso2,
    country_name: country.country,
  }));

export const isValidHostUrl = (url) => {
  const pattern = new RegExp(
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(:[0-9]{1,4})?', // port and path
    'i',
  );
  const checkSlash = url.includes('/');
  return !!pattern.test(url) && !checkSlash;
};

export const isValidURL = (url) => {
  if (!url) {
    return true;
  }
  const pattern = new RegExp(
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(url);
};

const validateIP = (ip) => {
  if (!ip) {
    return true;
  }
  const trimmedIP = ip.trim();
  return (
    ajv.validate(
      {
        type: 'string',
        format: 'ipv4',
      },
      trimmedIP,
    ) ||
    ajv.validate(
      {
        type: 'string',
        format: 'ipv6',
      },
      trimmedIP,
    )
  );
};

export const isValidIP = (ip, separator = ',') => {
  if (!ip) {
    return true;
  }
  const ips = ip.split(separator);
  const results = ips.filter((e) => !!e).map((e) => validateIP(e));
  if (results.length === 0) {
    return true;
  }
  return results.every((result) => !!result);
};

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const isValidDate = (date) => {
  if (!date) {
    return true;
  }
  return moment(date).isValid();
};

export const getRegistrationType = (type) => {
  switch (type) {
    case 1:
      return 'Sygna Gate';
    case 2:
      return 'Sygna Hub';
    case 3:
      return 'Sygna Email Protocol';
    default:
      return 'Sygna Bridge';
  }
};

export const isEmailValid = (email) => {
  if (!email) {
    return true;
  }
  return ajv.validate(
    {
      type: 'string',
      format: 'email',
    },
    email,
  );
};

export const parseCurrencies = (getCurrenciesResult) => {
  if (!getCurrenciesResult) {
    return [];
  }
  if (getCurrenciesResult.loading) {
    return [];
  }
  if (!getCurrenciesResult.data) {
    return [];
  }
  return getCurrenciesResult.data.getCurrencies;
};
